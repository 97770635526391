import { YapilyTransactionModel } from "./YapilyTransactionModel";

export class YapilyTransactionsModel {
  data : Array<YapilyTransactionModel>
  meta: {
    traceId: string;
    count: number;
  }
  links: {
    self: string
  }
}
