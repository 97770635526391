// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section#youtubevideo iframe {
  border-radius: 1rem;
  aspect-ratio: 16/9;
  max-width: 60rem;
  min-width: 60rem;
  width: 100%;
}
@media only screen and (max-width: 60rem) {
  section#youtubevideo iframe {
    min-width: 100%;
    width: 100%;
    aspect-ratio: 16/9;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/youtubevideo/youtubevideo.scss"],"names":[],"mappings":"AAEE;EACE,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,WAAA;AADJ;AAKE;EACE;IACE,eAAA;IACA,WAAA;IACA,kBAAA;EAHJ;AACF","sourcesContent":["section#youtubevideo{\n\n  iframe {\n    border-radius: 1rem;\n    aspect-ratio: 16/9;\n    max-width: 60rem;\n    min-width: 60rem;\n    width: 100%;\n  }\n\n  \n  @media only screen and (max-width: 60rem) {\n    iframe {\n      min-width: 100%;\n      width: 100%;\n      aspect-ratio: 16/9;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
