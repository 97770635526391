// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/info.png", import.meta.url);
// Module
var code = `<template>
  <require from="./transaction-component.scss"></require>
  <require from="../date-format-short"></require>
  <require from="../../components/date-format-full"></require>
  <require from="../ValueConverters/currency-denomination"></require>
  <require from="../number-format"></require>
  <require from="../ValueConverters/description-cleaner"></require>
  <require from="../ValueConverters/first-letter"></require>
  <require from="../ValueConverters/iban-converter"></require>
  <require from="../../components/loader-ring/loader-ring"></require>
  <require from="../../components/date-format-full-day"></require>
  <require from="../../components/number-format-eur"></require>
  
  <section id="transaction-component">
    <form class="inputs" if.bind="isSearchEnabled">
      <div>
        <input value.bind="search_term" type="text" i18n="[placeholder]home.recherche" />
        <i class="fas fa-search"></i>
      </div>
    </form>
    <div class="all_tx"> 
      <div 
        class="transaction"
        repeat.for="transaction of search_results_transactions"
        >
        <div id="top" class="top">
          <div class="picture flex-row">
            <img src.bind="transaction.urlPic">
            <div id="account" class="flex-column">
              <div class="name_date_container flex-column">
                <span class="name">\${transaction.senderName}</span><br>
              </div>
              <p class="account" if.bind="!state.wallets[state.selectedAccountIndex].isBlockchain">
                \${transaction.senderIdentification | ibanConverter}
              </p>
              <p class="account" if.bind="state.wallets[state.selectedAccountIndex].isBlockchain">
                \${transaction.senderIdentification}
              </p>
            </div>
          </div>
          <div class="amount flex-column">
            <div id="balance">
              <p id="Other-Amount" class="balance" if.bind="!allAccountsTogether[selectedAccountIndex].isBlockchain  && allAccountsTogether[selectedAccountIndex].currency !== 'EUR' && allAccountsTogether[selectedAccountIndex].currency  !== 'BSV'">
                \${transaction.amount}
              </p>
              <p id="EUR-Amount" class="balance" if.bind="!allAccountsTogether[selectedAccountIndex].isBlockchain && allAccountsTogether[selectedAccountIndex].currency === 'EUR'">
                \${transaction.amount | numberFormatEur:localeForCurrency}
                \${transaction.currency | currencyDenomination }
              </p>
            </div>
            <div id="date">
              <span class="date">\${transaction.createdAt | dateFormatFullDay:localeForCurrency}</span>
            </div>
          </div>
        </div>

        <div id="bottom" class="bottom" if.bind="isCommentPage && environment === 'test'">
          <p class="comment">\${transaction.senderNote}</p>
        </div>
      </div>
      <div class="empty flex-row" if.bind="search_results_transactions.length < 1">
        <img src="${___HTML_LOADER_IMPORT_0___}"/>
        <p t="bank.bank_no_transaction"></p>
      </div>
      <div class="flex-grow" if.bind="!txloading && search_results_transactions.length < 1"></div>
    </div>
  </section>
</template>`;
// Exports
export default code;