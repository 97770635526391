const endpoints = {

    // * yapily
    BANKS_AVAILABLE: (country: string) => `/yapily/banks/available/${country}`,
    ALL_BANKS: () => `/yapily/banks/all`,
    SPECIFIC_BANK: (institution) => `/yapily/bank/${institution}`,
    ALL_ACCOUNTS_FROM_BANK: (institution) => `/yapily/bank/${institution}/accounts/all`,
    SPECIFIC_ACCOUNT: (institution, account) => `/yapily/bank/${institution}/account/${account}`,
    TRANSACTIONS: (institution, account) => `/yapily/bank/${institution}/account/${account}/transactions`,
    CONSENT: `/yapily/consent`,

    // * autre
    RECIPIENT: (recipients_id) => `/recipients/' + ${recipients_id}`
}


/* '/token',
'/consent/',
'/account-auth-requests/',
'/payment-auth-requests/',
'/agreements/enduser/',
'/banks/all', <<<<<<<<< accounts
'/bank/:id',
'/bank/:institution/accounts/all',
'/bank/:institution/account/:account_id/transactions',
'/banks/:country',
'/payments/',
'/payments/:payments',
'/payments/:payments/details',
'/consent/:id',
'/consent/:id',
'/build/link/',
'/links/',
'/accounts/list/:requisition_id',
'/accounts/details/:account_id',
'/accounts/balances/:account_id',
'/accounts/transactions/:account_id', */

export default endpoints;
