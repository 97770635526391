import { autoinject, bindable, computedFrom } from "aurelia-framework";
import { YapilyTransactionModel } from "components/models/YapilyModels/YapilyTransactionModel";
import { Router } from "aurelia-router";
import { YapilyTransactionsModel } from "components/models/YapilyModels/YapilyTransactionsModel";
import { SingletonService } from "singleton";
import { RecipientsHttpClient } from "http_clients/RecipientsHttpClient";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { DialogService } from "aurelia-dialog";
import { TransactionsDetails } from "pages/bank/transactions_details/transactions_details";
import { IGenericTransaction } from "components/models/YapilyModels/IGenericTransaction";
import { connectTo } from "aurelia-store";
import { State } from "state";
import { default as env } from "../../../config/environment.json";


@connectTo()
@autoinject
export class TransactionComponent {
  @bindable private isSearchEnabled: boolean = false;
  @bindable private txloading: boolean = false;
  @bindable private limit: boolean = false;
  @bindable private transactions: Array<IGenericTransaction> = [];
  @bindable private isLoading: boolean = true;
  @bindable private blurred: boolean;
  private transaction_friday = [];
  private transaction_saturday = [];
  private transaction_sunday = [];
  private search_term: string = "";
  private transactions_by_day = [];
  private images;
  private isWallet: boolean = false;
  private currentRoute : string;
  private currentRouteBoolean : boolean;
  private environment : string;
  private hasCheckedRoute : boolean;


  constructor(
    private router: Router,
    private singleton: SingletonService,
    private recipientHttpClient: RecipientsHttpClient,
    private dialogService: DialogService,
    private state: State
  ) {
    this.router = router;
    this.environment = env.environment
    this.hasCheckedRoute = false; // Flag to track if we've checked the route
  }

  attached() {
    if (!this.hasCheckedRoute && this.router.currentInstruction) {
      this.currentRoute = this.router.currentInstruction.config.name;
      this.hasCheckedRoute = true; // Set the flag to true after checking
    }
  }
  
  @computedFrom('currentRoute')
  get isCommentPage() {
    return this.currentRoute === 'transaction_comments';
  }

  bind() {
    // Appelez la méthode pour initialiser les transactions après le bind
    this.initializeTransactions();
  }

  
  initializeTransactions() {
    // Assurez-vous que la state et les index nécessaires sont définis
    if (this.state.wallets && this.state.selectedAccountIndex !== undefined) {
        this.transactions = this.state.wallets[this.state.selectedAccountIndex].transactions;
    } else {
        // Si la state n'est pas encore définie, attendez un peu et réessayez
        setTimeout(() => {
            this.initializeTransactions();
          }, 100);
        }
      }
      
      @computedFrom("transactions", "search_term", "limit")
      get search_results_transactions(): Array<IGenericTransaction> {
        if (!this.transactions) {
          return [];
        }
        
        if (this.limit) {
          this.transactions.length = 8;
        }
        
        if (this.search_term == "") {
          return this.transactions;
    }

    let searchTerm = this.search_term.toString().toLowerCase();

    return this.transactions.filter(function(item: IGenericTransaction) {
      return (
        item.createdAt.toString().toLowerCase().indexOf(searchTerm) > -1 ||
        (item.senderNote == undefined ? "" : item.senderNote).toString().toLowerCase().indexOf(searchTerm) > -1 ||
        item.amount.toString().toLowerCase().indexOf(searchTerm) > -1 ||
        item.senderIdentification.toLowerCase().indexOf(searchTerm)  > -1 ||
        item.senderName.toLowerCase().indexOf(searchTerm)  > -1
      );
    });
  }
  
  // bind() {
    //   this.transactions = this.state.wallets[this.state.selectedAccountIndex].transactions;
    // }
    
    // @computedFrom("transactions", "search_term")
    // get search_results_transactions(): Array<IGenericTransaction> {
      //   if (this.limit) this.transactions.length = 8;
      
      //   if (this.search_term == "") return this.transactions;
      
      //   let that = this.search_term.toString();
      
  //   return this.transactions.filter(function (item: IGenericTransaction) {
  //     return (
  //       item.createdAt.toString().toLowerCase().indexOf(that.toLowerCase()) > -1 ||
  //       (item.senderNote == undefined ? "" : item.senderNote)
  //         .toString()
  //         .toLowerCase()
  //         .indexOf(that.toLowerCase()) > -1 ||
  //       item.amount.toString().toLowerCase().indexOf(that.toLowerCase()) > -1
  //     );
  //   });
  // }

  // async getTransactionPayee(iban) {
  //   let request = await this.recipientHttpClient.fetch(
  //     "recipients/filter/?iban" + iban
  //   );
  //   let is200Ok = await checkResponseStatus(request);
  //   let response = await is200Ok.json();
  // }

  // async goToTransactionDetails(tx: YapilyTransactionModel) {
  //   this.singleton.setSelectedTransaction(tx);
  //   // this.router.navigateToRoute("transactions_details")
  //   //Instead let's call the Dialog with the right page
  //   await this.dialogService
  //     .open({
  //       viewModel: TransactionsDetails,
  //     })
  //     .whenClosed((response) => {
  //       if (!response.wasCancelled) {
  //         //  console.log(response.output);
  //       } else {
  //         //was cancelled
  //       }
  //       // console.log(response.output);
  //     });
  //   // à faire
  // }

  // getBrandPicture(tx: any) {
  //   let reference = tx.reference || tx.description;
  //   // console.log(tx)
  //   try {
  //     if (reference.toLowerCase().includes("aliexpress")) return "img/brands/aliexpress.png";

  //     /* Others */
  //     else if (reference.toLowerCase().includes("kabel")) return "img/brands/kabel.jpg";
  //     else if (reference.toLowerCase().includes("la poste")) return "img/brands/laposte.png";
  //     else if (reference.toLowerCase().includes("bolt")) return "img/brands/bolt_eu.jpg";
  //     else if (reference.toLowerCase().includes("debuck")) return "img/brands/debuck_technolgies.png";


  //     /* TELECOMs */  
  //     else if (reference.toLowerCase().includes("bouygues"))
  //       return "img/brands/bouygues.png";
  //     else if (reference.toLowerCase().includes("o2")) return "img/brands/o2.png";
  //     else if (reference.toLowerCase().includes("orange"))
  //       return "img/brands/orange.png";
  //     else if (reference.toLowerCase().includes("proximus"))
  //       return "img/brands/proximus.png";
  //     else if (reference.toLowerCase().includes("t-online"))
  //       return "img/brands/t_online.png";
  //     else if (reference.toLowerCase().includes("tadaam"))
  //       return "img/brands/tadaam.png";
  //     else if (reference.toLowerCase().includes("telenet"))
  //       return "img/brands/telenet.png";
  //     else if (reference.toLowerCase().includes("sfr")) return "img/brands/sfr.png";
  //     else if (reference.toLowerCase().includes("vodafone"))
  //       return "img/brands/vodafone.png";
  //     else if (reference.toLowerCase().includes("voo")) return "img/brands/voo.png";
  //     else if (reference.toLowerCase().includes("free")) return "img/brands/free.png";
  //     else if (reference.toLowerCase().includes("discord"))
  //       return "img/brands/discord.png";

  //     /* restaurants */
  //     else if (reference.toLowerCase().includes("o-tacos"))
  //       return "img/brands/otacos.png";
  //     else if (reference.toLowerCase().includes("mcdonald"))
  //       return "img/brands/mcdonald.png";
  //     else if (reference.toLowerCase().includes("quick"))
  //       return "img/brands/quick.png";
  //     else if (reference.toLowerCase().includes("uber eats"))
  //       return "img/brands/uber_eats.png"; 
  //     else if (reference.toLowerCase().includes("starbucks"))
  //       return "img/brands/starbucks.png";
  //     else if (reference.toLowerCase().includes("burger king"))
  //       return "img/brands/burger_king.png";
  //     else if (reference.toLowerCase().includes("kfc")) return "img/brands/kfc.png";
  //     else if (reference.toLowerCase().includes("exki")) return "img/brands/exki.png";
  //     else if (reference.toLowerCase().includes("pizza hut")) return "img/brands/pizzahut.png";
  //     else if (reference.toLowerCase().includes("pianofabriek")) return "img/brands/pianofrabriek.png";
  //     else if (reference.toLowerCase().includes("cook & book")) return "img/brands/cookandbook.png";
  //     else if (reference.toLowerCase().includes("schievelavabo")) return "img/brands/schievelavabo.png";
  //     else if (reference.toLowerCase().includes("makisu")) return "img/brands/makisu.png";
  //     else if (reference.toLowerCase().includes("zanzibar 1348")) return "img/brands/zanzibar.png";
  //     else if (reference.toLowerCase().includes("deliveroo")) return "img/brands/deliveroo.png";
  //     else if (reference.toLowerCase().includes("belchicken")) return "img/brands/belchicken.png";
  //     else if (reference.toLowerCase().includes("be burger")) return "img/brands/beburger.png";
  //     else if (reference.toLowerCase().includes("green mango")) return "img/brands/greenmango.png"; 
  //     else if (reference.toLowerCase().includes("takeaway")) return "img/brands/takeaway.png"; 
        
  //     /* transportation */
  //     else if (reference.toLowerCase().includes("sncb")) return "img/brands/sncb.png";
  //     else if (reference.toLowerCase().includes("stib")) return "img/brands/stib.png";
  //     else if (reference.toLowerCase().includes("uber")) return "img/brands/uber.jpg";
  //     else if (reference.toLowerCase().includes("poppy")) return "img/brands/poppy.png";
  //     else if (reference.toLowerCase().includes("miles")) return "img/brands/miles.png";
  //     else if (reference.toLowerCase().includes("eurostar")) return "img/brands/eurostar.png";
      
  //     /* Entertainment */
  //     else if (reference.toLowerCase().includes("netflix"))
  //       return "img/brands/netflix.png";
  //     else if (reference.toLowerCase().includes("spotify"))
  //       return "img/brands/spotify.png";
  //     else if (reference.toLowerCase().includes("steam"))
  //       return "img/brands/steam.png";
      

  //     /* Big Retailers */
  //     else if (reference.toLowerCase().includes("amazon"))
  //       return "img/brands/amazon.png";
  //     else if (reference.toLowerCase().includes("microsoft"))
  //       return "img/brands/microsoft.png";
  //     else if (reference.toLowerCase().includes("google")) return "img/brands/google.png"; 
  //     else if (reference.toLowerCase().includes("sony")) return "img/brands/sony.png";
  //     else if (reference.toLowerCase().includes("fiverr")) return "img/brands/fiverr.png"; 
  //     else if (reference.toLowerCase().includes("kinepolis")) return "img/brands/kinepolis.png"; 
  //     else if (reference.toLowerCase().includes("okaidi")) return "img/brands/okaidi.png"; 
  //     else if (reference.toLowerCase().includes("cora")) return "img/brands/cora.png"; 
  //     else if (reference.toLowerCase().includes("auchan")) return "img/brands/auchan.png"; 
  //     else if (reference.toLowerCase().includes("cirque royal")) return "img/brands/cirqueroyal.png"; 
  //     else if (reference.toLowerCase().includes("carrefour")) 
  //       return "img/brands/carrefour.png";
  //     else if (reference.toLowerCase().includes("wish")) return "img/brands/wish.png";
  //     else if (reference.toLowerCase().includes("brico")) return "img/brands/brico.png";
  //     else if (reference.toLowerCase().includes("lidl")) return "img/brands/lidl.png";
  //     else if (reference.toLowerCase().includes("aldi")) return "img/brands/aldi.png";
  //     else if (reference.toLowerCase().includes("delhaize")) return "img/brands/delhaize.png";
  //     else if (reference.toLowerCase().includes("proxy")) return "img/brands/delhaize.png";
  //     else if (reference.toLowerCase().includes("ikea")) return "img/brands/ikea.png";


  //     /* GAS STATION */
  //     else if (reference.toLowerCase().includes("shell"))
  //       return "img/brands/shell.png";
  //     else if (reference.toLowerCase().includes("total"))
  //       return "img/brands/total.png";
  //       else if (reference.toLowerCase().includes("esso")) return "img/brands/esso.png";
  //     // else if (reference.toLowerCase().includes("bp")) return "img/brands/bp.png";
  //     else if (reference.toLowerCase().includes("q8")) return "img/brands/q8.png";

  //     /* GENERIC */
  //     else if (reference.toLowerCase().includes("pizza")) return "img/brands/pizza.png";
  //     else if (reference.toLowerCase().includes("pharma")) return "img/brands/pharmacie.png";
  //     else if (reference.toLowerCase().includes("parking")) return "img/brands/parking.png";
  //     else if (reference.toLowerCase().includes("park bru")) return "img/brands/parking.png";
  //     else if (reference.toLowerCase().includes("spf finances")) return "img/brands/spf finance.png";
  //     else if (reference.toLowerCase().includes("onss")) return "img/brands/onss.png";

  //     /* Payment providers */
  //     else if (reference.toLowerCase().includes("paypal"))
  //       return "img/brands/paypal.png";
  //     else if (reference.toLowerCase().includes("payconiq")) return "img/brands/payconiq.png";
  //     else if (reference.toLowerCase().includes("bancontact"))
  //       return "img/brands/bancontact.png";     
      
  //     // else {
  //     //   return "img/wallet_white.svg";
  //     // }
  //   } catch (e) {
  //     // console.log("no image found")
  //     return "img/wallet_white.svg";
  //   }
  // }
}


